import {
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
  useCallback,
  lazy,
  Suspense,
  useRef,
} from 'react';

import { useQuery, gql, useMutation } from '@apollo/client';
import { Spin } from 'antd';
import {
  Mousewheel,
  Navigation,
  Scrollbar,
  FreeMode,
  Lazy,
  Autoplay,
  Pagination,
} from 'swiper-cjs';
import { useConfig, useProfile } from '../hooks';

import BgHome2 from '../../scss/images/bg-home-2.webp';
import BgHome2_768 from '../../scss/images/bg-home-2-768px.webp';
import BgHome2_480 from '../../scss/images/bg-home-2-480px.webp';
import { useTranslation } from 'react-i18next';
import { result } from 'lodash';
import { Link } from 'react-router-dom';
import { useOpenModal } from '~components/modals/hooks';
import loadable from '@loadable/component';

import { Swiper, SwiperSlide } from 'swiper-cjs/react';

// const SlickSlider = loadable(() => import('react-slick'), {
//   ssr: false,
// });

// const SwiperModule = loadable(() => import('swiper/react'), {
//   ssr: false,
// });

// const SwiperSlide = loadable(() => import('swiper/react'), {
//   ssr: false,
// }).SwiperSlide;

interface SliderProps {
  items: ReactNode[]; // Массив слайдов, может содержать HTML-разметку, компоненты или картинки
}

const FRAGMENT_BANNER_FILEDS = gql`
  fragment GetBannerFields on Banner {
    id
    image
    isActive
    name
    header
    subHeader
    notAuthorizedHeader
    notAuthorizedSubHeader
    btnText
    modalText
    modalHeader
    type
  }
`;

const formatHeader = (header: string): React.ReactNode => {
  // Regular expressions to match numbers and special characters
  const numberRegex = /\d+/g; // Matches consecutive digits
  const specialCharRegex = /[.,!?\-]/g; // Matches special characters

  // Use a regular expression to split the header while keeping delimiters
  const parts = header.split(/(\d+|[.,!?\-])/g);

  // Map over each part and wrap in the corresponding span tags if needed
  return parts.map((part, index) => {
    if (numberRegex.test(part)) {
      return (
        <span key={index} className="knewave-font">
          {part}
        </span>
      );
    } else if (specialCharRegex.test(part)) {
      return (
        <span key={index} className="pangolin-font ">
          {part}
        </span>
      );
    } else {
      return part; // Return plain text for other parts
    }
  });
};
export const Slider1 = ({ items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animationDirection, setAnimationDirection] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [sliderItems, setSliderItems] = useState(items);
  const { t, i18n } = useTranslation();
  const getProfile = useProfile();
  const getConfig = useConfig();

  const isMobile =
    getConfig?.userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ) ?? false;

  // console.log('isMobile', isMobile);
  // console.log('userAgent', getConfig?.userAgent);
  const { onShow } = useOpenModal();

  const { loading, error, data, networkStatus } = useQuery(
    gql`
      query getSliderData {
        getActiveBanners {
          ...GetBannerFields
        }

        getConfig {
          nextBannerTimer
        }
      }

      ${FRAGMENT_BANNER_FILEDS}
    `,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  // console.log('Loading:', loading);
  // console.log('Error:', error);
  // console.log('Data:', data);
  // console.log('Network Status:', networkStatus);

  const getActiveBanners = data?.getActiveBanners;
  const nextBannerTimer = data?.getConfig?.nextBannerTimer;

  //console.log('nextTimer', nextBannerTimer);

  const viewModal = (modalText: any, modalHeader: any) => {
    console.log('show1');
    if (getProfile) {
      onShow('BannerModal', { modalText, modalHeader });
    } else {
      onShow('ForgotModal', { modalText, modalHeader });
    }
  };

  useEffect(() => {
    if (getActiveBanners) {
      const newItems = getActiveBanners.map((banner: any) => {
        const subHeader = getProfile
          ? result(banner.subHeader, [i18n.language], banner.subHeader?.en)
          : result(
              banner.notAuthorizedSubHeader,
              [i18n.language],
              banner.notAuthorizedSubHeader?.en,
            );
        const header = getProfile
          ? result(banner.header, [i18n.language], banner.header?.en)
          : result(
              banner.notAuthorizedHeader,
              [i18n.language],
              banner.notAuthorizedHeader?.en,
            );

        return (
          // TO DO: check this 0 important on the dev padding with 0 works and accepts for mobile,
          // but on prod this padding 0 not working. So used style={{ paddingBottom: isMobile ? '170px' : '0px' }} for prod
          // which actually not needed for dev version
          <section
            key={banner.id}
            // style={{ paddingTop: `0 !important;!i;!` }}
            ref={(el) => {
              if (el) el.style.setProperty('padding-top', '0', 'important');
            }}
            className={`open-cases  ${
              !getProfile && 'open-cases-home'
            } open-cases-breezy open-cases-battle-pass`}
          >
            <div
              className="breezy-home-content"
              // style={{
              //   marginTop:
              //     subHeader && banner.type > 0
              //       ? '-100px'
              //       : subHeader || banner.type > 0
              //       ? '-60px'
              //       : '0',
              //   paddingTop: '20px',
              //   paddingLeft: '30px',
              // }}
              // style={{
              //   paddingLeft: isMobile ? '5px' : '40px',
              //   paddingRight: isMobile ? '5px' : '40px',
              //   paddingBottom: isMobile ? '0' : '170px',
              //   marginTop: isMobile ? '0' : '-50px',
              // }}
            >
              <h1 className="breezy-home-title">{header}</h1>
              <h2 className="subtitle">{subHeader}</h2>

              {banner.type > 0 && (
                <Link
                  className="breezy-home-btn"
                  to={'/'}
                  onClick={() =>
                    viewModal(banner.modalText, banner.modalHeader)
                  }
                >
                  <span
                    className="breezy-home-btn-text"
                    dangerouslySetInnerHTML={{
                      __html: result(
                        banner.btnText,
                        [i18n.language],
                        banner.btnText?.en,
                      ),
                    }}
                  />
                </Link>
              )}
            </div>
            <img src={banner.image} alt={banner.name} />
          </section>
        );
      });
      const oldItems = items.map((item, index) => {
        return (
          <section
            key={index}
            // style={{
            //   alignItems: isMobile ? 'center' : 'normal',
            //   marginTop: isMobile ? '0' : '-25px',
            // }}
            ref={(el) => {
              if (el) el.style.setProperty('padding-top', '0', 'important');
            }}
            className={`open-cases  ${
              !getProfile && 'open-cases-home'
            } open-cases-breezy open-cases-battle-pass`}
          >
            {item}
          </section>
        );
      });
      setSliderItems([...newItems, ...oldItems]); // Объединяем старые и новые элементы
    }
    //console.log('items', sliderItems);
  }, [getActiveBanners, i18n.language, getProfile]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Функции переключения слайдов
  const prevSlide = () => {
    setAnimationDirection('left');
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentSlide(
        (prev) => (prev - 1 + sliderItems.length) % sliderItems.length,
      );
      setIsAnimating(false);
    }, 500); // Длительность анимации
  };

  const nextSlide = () => {
    setAnimationDirection('right');
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentSlide((prev) => (prev + 1) % sliderItems.length);
      setIsAnimating(false);
    }, 500); // Длительность анимации
  };

  // Таймер для автоматического переключения слайдов
  useEffect(() => {
    if (sliderItems.length > 1) {
      const interval = setInterval(() => {
        nextSlide();
      }, nextBannerTimer * 1000);

      return () => clearInterval(interval);
    }
  }, [currentSlide, sliderItems.length]);

  if (loading && [1, 2].includes(networkStatus)) {
    return <Spin />;
  } else if (error) {
    return <>Banner | Error! {error.message}</>;
  }

  return (
    // <>
    //   {sliderItems.length >= 1 && (
    //     <section
    //       className={`open-cases open-cases-home open-cases-breezy open-cases-battle-pass`}
    //     >
    //       <div>
    //         {sliderItems.length > 1 && (
    //           <button className="slider-button prev-button" onClick={prevSlide}>
    //             &lt;
    //           </button>
    //         )}
    //         <div
    //           className={`slider-content ${animationDirection} ${
    //             isAnimating ? 'fade-out' : 'fade-in'
    //           }`}
    //         >
    //           {sliderItems[currentSlide]}
    //         </div>
    //         {sliderItems.length > 1 && (
    //           <button className="slider-button next-button" onClick={nextSlide}>
    //             &gt;
    //           </button>
    //         )}
    //       </div>
    //       {sliderItems.length > 1 && (
    //         <div className="slider-indicators">
    //           {sliderItems.map((_, index) => (
    //             <div
    //               key={index}
    //               className={`slider-indicator ${
    //                 currentSlide === index ? 'active' : ''
    //               }`}
    //               style={{
    //                 opacity: currentSlide === index ? 1 : 0.5,
    //               }}
    //             ></div>
    //           ))}
    //         </div>
    //       )}
    //     </section>
    //   )}
    // </>
    <>
      {sliderItems.length > 0 ? (
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          className={
            getConfig?.seo?.distributionActive
              ? 'slider'
              : 'slider-without-distributions'
          }
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          pagination={{
            // el: '.swiper-pagination',
            // type: 'bullets',
            clickable: true,
            bulletActiveClass: 'swiper-pagination-bullet-active',
            bulletClass: 'swiper-pagination-bullet',
          }}
          autoplay={{ delay: nextBannerTimer * 1000 }}
          lazy={{ loadPrevNext: true, loadPrevNextAmount: 2 }}
          loop={sliderItems?.length > 1 ? true : false}
          modules={[
            Mousewheel,
            Pagination,
            Navigation,
            Scrollbar,
            FreeMode,
            Lazy,
            Autoplay,
          ]}
        >
          {sliderItems.map((item, index) => (
            <SwiperSlide style={{ zIndex: 3 }} key={index}>
              {item}
            </SwiperSlide>
          ))}
          {!isMobile && sliderItems?.length > 1 && (
            <div
              className="swiper-button-next"
              style={{ color: 'rgba(153, 86, 254, 0.5)' }}
            ></div>
          )}
          {!isMobile && sliderItems?.length > 1 && (
            <div
              className="swiper-button-prev"
              style={{ color: 'rgba(153, 86, 254, 0.5)' }}
            ></div>
          )}
        </Swiper>
      ) : (
        <></>
      )}
    </>
  );
};
