import {
  memo,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Category as CategoryType, CategoryInputWhere } from '~types';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { CaseBlock } from './CaseBlock';
import { useGetCategories } from '../hooks';
import { Loader } from '../../Loader';

interface CategoryProps {
  getCategory: CategoryType;
  isBattlePassCoins?: boolean;
}

export const Category: FunctionComponent<CategoryProps> = memo(
  ({ getCategory, isBattlePassCoins }: CategoryProps) => (
    <article
      className={`case-row ${getCategory.isSmall && 'case-row-small'}`}
      data-rar={getCategory?.color}
      style={{
        background:
          'linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(18,0,31,1) 35%, rgba(2,0,36,1) 100%)', // Gradient background
        boxShadow: 'none',
      }}
      data-id={getCategory.id}
    >
      <div className="case-row-title">
        <div
          className="case-row-title-name"
          style={{ color: getCategory.color }}
        >
          <span className="mask"></span>
          <span className="name">{getCategory.getName}</span>
        </div>
        {getCategory.getDescription && (
          <div className="case-row-title-sec">{getCategory.getDescription}</div>
        )}
      </div>
      <div className="case-item-wrap">
        {_.map(getCategory?.getCases, (getCase) => (
          <CaseBlock
            getCase={getCase}
            key={getCase?.id}
            isBattlePassCoins={isBattlePassCoins}
          />
        ))}
      </div>
    </article>
  ),
);

Category.displayName = 'Category';

interface ICategoriesView {
  where?: CategoryInputWhere;
  className?: string | undefined;
}

export const CategoriesView: FunctionComponent<ICategoriesView> = memo(
  ({ where, className = 'cases cases-all' }) => {
    const { loading, error, data, fetchMore, refetch } =
      useGetCategories(where);
    const [attempts, setAttempts] = useState(0);
    const MAX_ATTEMPTS = 3; // Максимальное количество попыток

    let getCategories = data?.getCategories?.getCategories;
    console.log('getCategories', getCategories);
    const count = data?.getCategories?.count;
    const currentLength = _.size(getCategories);
    if (!_.isEmpty(getCategories) && attempts < 1) {
      console.log('delete cats');
      //getCategories = {} as any;
    }
    // useEffect(() => {
    //   if (
    //     !loading &&
    //     !error &&
    //     _.isEmpty(getCategories) &&
    //     attempts < MAX_ATTEMPTS
    //   ) {
    //     console.log('refetch');
    //     const timer = setTimeout(() => {
    //       setAttempts(attempts + 1);
    //       refetch();
    //     }, 2000); // Повторный запрос через 2 секунды

    //     return () => clearTimeout(timer);
    //   }
    // }, [loading, error, getCategories, attempts, refetch]);

    const onLoadMore = useCallback(async () => {
      try {
        await fetchMore({
          variables: {
            input: { offset: currentLength, limit: 2 },
          },
        });
      } catch (error) {
        console.error('Error fetching more categories:', error);
      }
    }, [currentLength, fetchMore]);

    if (loading) {
      return <Loader />;
    } else if (error) {
      return <>GetCategories | Error! {error.message}</>;
    } else if (!getCategories || getCategories.length === 0) {
      return <>No categories found.</>;
    }

    return (
      <section className={className}>
        <InfiniteScroll
          pageStart={0}
          loadMore={onLoadMore}
          hasMore={currentLength < count}
          loader={<Loader key={0} />}
          className="row"
        >
          {getCategories.map((getCategory) => (
            <Category key={getCategory.id} getCategory={getCategory} />
          ))}
        </InfiniteScroll>
      </section>
    );
  },
);
